import { useRef } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const allClients = [
	'/clients/client12.png',
	'/clients/client35.png',
	'/clients/client2.png',
	'/clients/client36.png',
	'/clients/client37.png',
	'/clients/client3.png',
	'/clients/client4.png',
	'/clients/client5.png',
	'/clients/client7.png',
	'/clients/client38.jpeg',
	'/clients/client20.png',
	'/clients/client8.png',
	'/clients/client9.png',
	'/clients/client10.png',
	'/clients/client6.png',
	'/clients/client24.png',
	'/clients/client29.png',
	'/clients/client1.png',
	'/clients/client17.png',
	'/clients/client15.png',
	'/clients/client13.png',
	'/clients/client21.png',
	'/clients/client25.png',
	'/clients/client23.png',
	'/clients/client16.png',
	'/clients/client22.png',
	'/clients/client11.png',
	'/clients/client34.png',
	'/clients/client30.png',
	'/clients/client14.png',
	'/clients/client19.png',
	'/clients/client18.png',
	'/clients/client26.png',
];

function EmptyArrow(props) {
	return <div className='hidden' />;
}

export default function Clients() {
	let sliderRef = useRef(null);

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 2000,
		pauseOnHover: false,
		nextArrow: <EmptyArrow />,
		prevArrow: <EmptyArrow />,
	};

	return (
		<div className='flex w-full flex-col justify-center gap-10 bg-white p-8'>
			<h3 className='section-title'>Satisfied Clients</h3>

			<div className='h-[20vh]'>
				<Slider
					ref={(slider) => (sliderRef = slider)}
					{...settings}>
					{allClients.map((client, index) => (
						<div
							key={index}
							className='!flex h-[20vh] w-28 items-center justify-center'>
							<img
								src={client}
								className='max-h-full'
							/>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
}
