// TODO: the size of some items

const allClients = [
	'/clients/client12.png',
	'/clients/client35.png',
	'/clients/client2.png',
	'/clients/client36.png',
	'/clients/client37.png',
	'/clients/client3.png',
	'/clients/client4.png',
	'/clients/client5.png',
	'/clients/client7.png',
	'/clients/client38.jpeg',
	'/clients/client20.png',
	'/clients/client8.png',
	'/clients/client9.png',
	'/clients/client10.png',
	'/clients/client6.png',
	'/clients/client24.png',
	'/clients/client29.png',
	'/clients/client1.png',
	'/clients/client17.png',
	'/clients/client15.png',
	'/clients/client13.png',
	'/clients/client21.png',
	'/clients/client25.png',
	'/clients/client23.png',
	'/clients/client16.png',
	'/clients/client22.png',
	'/clients/client11.png',
	'/clients/client34.png',
	'/clients/client30.png',
	'/clients/client14.png',
	'/clients/client19.png',
	'/clients/client18.png',
	'/clients/client26.png',
];

export default function OurClients() {
	return (
		<div className='mx-auto min-h-[calc(100vh_-_64px)] w-[80%] p-10'>
			<h3 className='section-title'>Our Clients</h3>

			<p className='mx-auto mt-5 w-[80%] text-center text-lg'>
				We believe in building strong, lasting relationships with each client.
				Our dedication to providing exceptional service and tailored solutions
				ensures that our clients’ needs are met and their expectations exceeded.
				As our pool of clients continues to grow, we remain committed to each
				one’s success, working closely to understand and support their unique
				goals.
			</p>

			<p className='mx-auto my-5 w-[80%] text-center text-lg text-gray-600'>
				A snapshot of the amazing 35+ clients we've partnered with.
			</p>

			<div className='mx-auto grid h-full w-[80%] grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3'>
				{allClients.map((client, index) => (
					<div
						key={index}
						className='flex h-[20vh] w-full items-center justify-center'>
						<img
							src={client}
							className='max-h-full max-w-full object-contain'
						/>
					</div>
				))}
			</div>
		</div>
	);
}
