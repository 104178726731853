import Phone from '../../assets/svgs/phone.svg';
import Email from '../../assets/svgs/email.svg';
import Location from '../../assets/svgs/location.svg';
import clsx from 'clsx';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const successNotify = () =>
	toast.success('Message sent successfully! We will contact you soon.', {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	});

const errorNotify = () =>
	toast.error('Sorry, an error occurred, please try again.', {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	});

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

const Form = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [isSending, setIsSending] = useState(false);

	const resetForm = () => {
		setName('');
		setEmail('');
		setSubject('');
		setMessage('');
		setIsSending(false);
	};

	const sendEmail = (e) => {
		e.preventDefault();

		const content = {
			subject,
			from_name: name,
			from_email: email,
			message,
		};

		emailjs.send(SERVICE_ID, TEMPLATE_ID, content, PUBLIC_KEY).then(
			(_) => {
				successNotify();
				resetForm();
			},
			(error) => {
				console.error('~ error:', error);
				errorNotify();
				setIsSending(false);
			}
		);
	};

	return (
		<>
			<h2 className='mb-6 text-center text-2xl font-medium text-white'>
				Send us a message
			</h2>

			<form onSubmit={sendEmail}>
				<div className='mb-4'>
					<label
						className='mb-1 block text-sm text-gray-300'
						htmlFor='name'>
						Name
					</label>
					<input
						type='text'
						id='name'
						name='name'
						value={name}
						onChange={(e) => setName(e.target.value)}
						className='w-full rounded-md border px-4 py-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-orange-500'
					/>
				</div>
				<div className='mb-4'>
					<label
						className='mb-1 block text-sm text-gray-300'
						htmlFor='email'>
						Email
					</label>
					<input
						type='email'
						id='email'
						name='email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className='w-full rounded-md border px-4 py-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-orange-500'
					/>
				</div>
				<div className='mb-4'>
					<label
						className='mb-1 block text-sm text-gray-300'
						htmlFor='subject'>
						Subject
					</label>
					<input
						type='text'
						id='subject'
						name='subject'
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						className='w-full rounded-md border px-4 py-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-orange-500'
					/>
				</div>
				<div className='mb-6'>
					<label
						className='mb-1 block text-sm text-gray-300'
						htmlFor='message'>
						Message
					</label>
					<textarea
						id='message'
						rows='4'
						name='message'
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className='w-full rounded-md border px-4 py-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-orange-500'></textarea>
				</div>
				<button
					type='submit'
					disabled={isSending}
					className='w-full rounded-md bg-orange-500 py-2 text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 disabled:cursor-not-allowed disabled:opacity-80'>
					{isSending ? 'Sending...' : 'Send Message'}
				</button>
			</form>

			<ToastContainer />
		</>
	);
};

const ContactItem = (props) => {
	const { index, name, icon, info } = props;

	return (
		<li className='mb-3 flex items-center'>
			<div className='mr-3 flex h-14 w-14 min-w-14 items-center justify-center rounded-full bg-primary-800 p-2'>
				<img
					src={icon}
					alt={name}
					className='h-5 w-5'
				/>
			</div>
			<p
				className={clsx(
					index === 2 ? '' : 'text-lg',
					'flex h-14 flex-1 items-center justify-center rounded-[50px] bg-[#d0e7f9] px-2 text-center font-semibold leading-[20px] text-primary-800'
				)}>
				{info}
			</p>
		</li>
	);
};

export default function ContactUs() {
	const contactUsInfo = [
		{
			name: 'phone',
			icon: Phone,
			info: '+966 563578523',
		},
		{
			name: 'email',
			icon: Email,
			info: 'info@sawaedco.com',
		},
		{
			name: 'location',
			icon: Location,
			info: '2727 Al Washm, Al-Murabba District, Riyadh',
		},
	];

	return (
		<div className='mx-auto flex min-h-[calc(100vh_-_64px)] w-[70%] items-center p-10'>
			<div
				className='flex h-full w-full'
				style={{ boxShadow: '0px 21px 41px -13px rgba(0, 0, 0, 0.18)' }}>
				<div
					style={{
						background: 'linear-gradient(135deg, #231d7b 0%,  #1d295b  100%)',
					}}
					className='min-h-[70vh] w-[50%] p-[3rem]'>
					<Form />
				</div>
				<div className='h-[70vh] w-[50%] bg-white p-[3rem]'>
					<h3 className='section-title'>Contact Us</h3>
					<p className='mx-auto my-5 w-[85%] text-center text-lg'>
						Have some great ideas to develop or you need help? Reach out we'd
						love to hear from you and provide help.
					</p>
					<ul className='mx-auto my-8 w-[80%]'>
						{contactUsInfo.map((item, index) => (
							<ContactItem
								key={index}
								index={index}
								{...item}
							/>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}
