export const projects = {
	WaterTreatments: {
		id: 'WaterTreatments',
		title: 'Water Treatments (lines in Al-Aflaj Gov, aL BUWAIB)',
		contractor: 'Ministry of Environment Water & Agriculture',
		client: [
			'National Water Company',
			'Ministry of Environment Water & Agriculture',
		],
		clientImage: ['/clients/client38.jpeg', '/clients/client39.jpeg'],
		duration: '2 years',
		description:
			'Civil and Architectural Finishing Works with Epoxy Coating and HDPE Line Installation for Evaporation Lakes.',
		furtherDescription:
			'This project involved comprehensive civil and architectural finishing works, ensuring high-quality standards for durability and functionality. The scope included the application of specialized epoxy coatings for enhanced surface protection and longevity. Additionally, the project featured the installation of High-Density Polyethylene (HDPE) lines in evaporation lakes, which required precision welding operations to ensure a seamless and secure fit. As part of the water treatment plant development, the project also involved lining evaporation lakes to optimize containment and operational efficiency. These efforts collectively contributed to the successful development of a reliable and sustainable water treatment infrastructure.',
		tags: ['Implementing water tanks', 'HDPE Lines'],
		gallery: [
			'/projects/WaterTreatments/1.jpeg',
			'/projects/WaterTreatments/2.jpeg',
			'/projects/WaterTreatments/3.jpeg',
			'/projects/WaterTreatments/4.jpeg',
			'/projects/WaterTreatments/5.jpeg',
			'/projects/WaterTreatments/6.jpeg',
			'/projects/WaterTreatments/7.jpeg',
			'/projects/WaterTreatments/8.jpeg',
			'/projects/WaterTreatments/9.jpeg',
			'/projects/WaterTreatments/10.jpeg',
			'/projects/WaterTreatments/11.jpeg',
			'/projects/WaterTreatments/12.jpeg',
			'/projects/WaterTreatments/13.jpeg',
			'/projects/WaterTreatments/14.jpeg',
			'/projects/WaterTreatments/15.jpeg',
			'/projects/WaterTreatments/16.jpeg',
			'/projects/WaterTreatments/17.jpeg',
			'/projects/WaterTreatments/18.jpeg',
			'/projects/WaterTreatments/19.jpeg',
			'/projects/WaterTreatments/20.jpeg',
			'/projects/WaterTreatments/21.jpeg',
		],
	},
	QiddiyaWaterThemePark: {
		id: 'QiddiyaWaterThemePark',
		title: 'Qiddiya Water Theme Park',
		contractor: 'Voltas',
		client: 'Al Qiddiya',
		clientImage: '/clients/client12.png',
		duration: '2 years',
		description:
			'Infrastructure work - supply & installation of storm water system/sewer Network/water supply system/fire fighting system/Chilled water Network/Electrical Installations including switch gear, transformers, Rmu. LV, WLV Ducting & Cable installations',
		furtherDescription:
			'Supply, Installation, Testing & commissioning of Infra-structure MEP works including Electrical Installations (Switch gear, Transformers, Rmu) and mechanical installations (Storm, sewerage, firefighting, potable water, Irrigation water, chilled water network with leak detection system, LPG network with LPH tanks & LV, ELV Ducting work.',
		tags: ['water system', 'infrastructure'],
		gallery: [
			'/projects/QiddiyaWaterThemePark/0.png',
			'/projects/QiddiyaWaterThemePark/1.png',
			'/projects/QiddiyaWaterThemePark/2.png',
			'/projects/QiddiyaWaterThemePark/3.png',
			'/projects/QiddiyaWaterThemePark/4.png',
			'/projects/QiddiyaWaterThemePark/5.png',
			'/projects/QiddiyaWaterThemePark/6.png',
			'/projects/QiddiyaWaterThemePark/7.png',
			'/projects/QiddiyaWaterThemePark/8.png',
		],
	},
	RoshanVillas: {
		id: 'RoshanVillas',
		title: 'Roshan Villas',
		contractor: 'Shapoorji Pallonji Mideast',
		client: 'Roshan',
		clientImage: '/clients/client11.png',
		duration: '2 years',
		description:
			'Supply & installation of mechanical, electrical and air conditioning works (VRF), including the establishment and finishing of all internal and external works (600villas).',
		furtherDescription:
			'MEP installations, testing & commissioning of 300 luxury villas at Riyadh Front area including electrical, Plumbing, Telecom and HVAC (VRF system).',
		tags: ['VFR', 'villas'],
		gallery: [
			'/projects/RoshanVillas/1.png',
			'/projects/RoshanVillas/2.png',
			'/projects/RoshanVillas/3.png',
			'/projects/RoshanVillas/4.png',
			'/projects/RoshanVillas/5.png',
			'/projects/RoshanVillas/6.png',
			'/projects/RoshanVillas/7.png',
			'/projects/RoshanVillas/8.jpeg',
			'/projects/RoshanVillas/9.jpeg',
			'/projects/RoshanVillas/10.jpeg',
			'/projects/RoshanVillas/11.jpeg',
			'/projects/RoshanVillas/12.jpeg',
			'/projects/RoshanVillas/13.jpeg',
			'/projects/RoshanVillas/14.jpeg',
			'/projects/RoshanVillas/15.jpeg',
			'/projects/RoshanVillas/16.jpeg',
			'/projects/RoshanVillas/17.jpeg',
			'/projects/RoshanVillas/18.jpeg',
		],
	},
	AlBasateenVillas: {
		id: 'AlBasateenVillas',
		title: 'Al-Basateen Villas',
		contractor: 'Upmoc Company',
		client: 'AICC',
		clientImage: '/clients/client23.png',
		duration: '1 year',
		description:
			'Supply & installation of mechanical, electrical and air conditioning works (VRF), including the establishment and finishing of all internal and external works (600villas).',
		furtherDescription:
			'MEP installations, testing & commissioning of  25 luxury villas at Al Diriyah Distract including Electrical, Plumbing, Telecom and HVAC (VRF system). ',
		tags: ['VFR', 'villas'],
		gallery: [
			'/projects/AlBasateenVillas/1.png',
			'/projects/AlBasateenVillas/2.png',
			'/projects/AlBasateenVillas/3.png',
			'/projects/AlBasateenVillas/4.png',
			'/projects/AlBasateenVillas/5.png',
			'/projects/AlBasateenVillas/6.png',
			'/projects/AlBasateenVillas/7.png',
			'/projects/AlBasateenVillas/8.png',
			'/projects/AlBasateenVillas/9.png',
			'/projects/AlBasateenVillas/10.png',
		],
	},
	Exit10Development: {
		id: 'Exit10Development',
		title: 'Exit-10 Development',
		contractor: 'Shapoorji Pallonji Mideast',
		client: 'Exit 10/ Seven',
		clientImage: '/clients/client11.png',
		duration: '1 year',
		description:
			'Supply & installation of mechanical and electrical works, including grounding, boards and foundation works, with central air conditioning system and firefighting.',
		furtherDescription:
			'Exit 10 ( Seven ) Projet, MEP Services including (Electrical, Earthing, and Plumbing works).',
		tags: ['Mechanical', 'Electrical'],
		gallery: [
			'/projects/Exit10Development/1.png',
			'/projects/Exit10Development/2.png',
			'/projects/Exit10Development/3.png',
			'/projects/Exit10Development/4.png',
			'/projects/Exit10Development/5.png',
			'/projects/Exit10Development/6.png',
			'/projects/Exit10Development/7.png',
			'/projects/Exit10Development/8.png',
			'/projects/Exit10Development/9.png',
			'/projects/Exit10Development/10.png',
		],
	},
	SportsCityAlKharj: {
		id: 'SportsCityAlKharj',
		title: 'Al Kharj Sports Complex',
		contractor: 'Fan Al Me’maar',
		client: 'Ministry of Sports',
		clientImage: '/clients/client27.png',
		duration: '1 year',
		description:
			'Supply and installation of electromechanical structural and architectural works.',
		furtherDescription: '',
		tags: ['Electromechanical', 'Structural'],
		gallery: [
			'/projects/SportsCityAlKharj/2.jpeg',
			'/projects/SportsCityAlKharj/3.jpeg',
			'/projects/SportsCityAlKharj/4.jpeg',
			'/projects/SportsCityAlKharj/5.jpeg',
			'/projects/SportsCityAlKharj/1.png',
			'/projects/SportsCityAlKharj/6.jpeg',
			'/projects/SportsCityAlKharj/7.jpeg',
			'/projects/SportsCityAlKharj/8.jpeg',
			'/projects/SportsCityAlKharj/9.jpeg',
			'/projects/SportsCityAlKharj/10.jpeg',
			'/projects/SportsCityAlKharj/11.jpeg',
			'/projects/SportsCityAlKharj/12.jpeg',
			'/projects/SportsCityAlKharj/13.jpeg',
			'/projects/SportsCityAlKharj/14.jpeg',
		],
	},
	Neom: {
		id: 'Neom',
		title: 'Al-Owaidah Palace',
		contractor: 'Shapoorji Pallonji Mideast',
		client: 'Neom',
		clientImage: '/clients/client8.png',
		duration: '1 year',
		description:
			'Installation, testing, and commissioning infrastructure works (plumbing, fire fighting, medium voltage, low voltage, switch gears, and transformers.',
		furtherDescription: '',
		tags: ['Infrastructure'],
		gallery: [
			'/projects/Neom/1.png',
			'/projects/Neom/2.png',
			'/projects/Neom/3.png',
			'/projects/Neom/4.png',
			'/projects/Neom/5.png',
			'/projects/Neom/6.png',
			'/projects/Neom/7.png',
			'/projects/Neom/8.png',
		],
	},
	GoldenChickenFactory: {
		id: 'GoldenChickenFactory',
		title: 'Golden Chicken Factory',
		contractor: 'York Company',
		client: 'Johnson Control / Golden Chicken',
		clientImage: '/clients/client13.png',
		duration: '6 Months',
		description:
			'Design & implementation of central AC works in the factory, including installation of units with connections, extensions and operation.',
		furtherDescription:
			'Supply and Installation of Chilled Water Network, Ventilation System, and DX Units for separate Buildings, VRF System, and Control System Works for all the mentioned.',
		tags: ['Factory'],
		gallery: [
			'/projects/GoldenChickenFactory/1.png',
			'/projects/GoldenChickenFactory/2.png',
			'/projects/GoldenChickenFactory/3.png',
			'/projects/GoldenChickenFactory/4.png',
			'/projects/GoldenChickenFactory/5.png',
			'/projects/GoldenChickenFactory/6.png',
			'/projects/GoldenChickenFactory/7.png',
			'/projects/GoldenChickenFactory/8.png',
			'/projects/GoldenChickenFactory/9.png',
		],
	},
	SalbookhAirbase: {
		id: 'SalbookhAirbase',
		title: 'Improvements of Salbookh Airbase',
		contractor: '',
		client: 'BSS',
		clientImage: '/clients/client14.png',
		duration: '2 years',
		description: 'Civil works for open storm drainage channels.',
		furtherDescription: 'Civil works for open storm drainage channels.',
		tags: ['Storm Drainage'],
		gallery: [
			'/projects/SalbookhAirbase/1.png',
			'/projects/SalbookhAirbase/2.png',
		],
	},
	IslamicComplexCourts: {
		id: 'IslamicComplexCourts',
		title: 'Islamic Complex Courts',
		contractor: 'Al-saghyir Company',
		client: 'Ministry of Justice',
		clientImage: '/clients/client3.png',
		duration: '',
		description: 'Al Madinah Islamic complex courts',
		furtherDescription:
			'Design, supply, installation and maintenance of fire network and central air conditioning.',
		tags: ['Infrastructure', 'Fire network'],
		gallery: [
			'/projects/IslamicComplexCourts/1.png',
			'/projects/IslamicComplexCourts/2.png',
			'/projects/IslamicComplexCourts/3.png',
			'/projects/IslamicComplexCourts/4.png',
			'/projects/IslamicComplexCourts/5.png',
			'/projects/IslamicComplexCourts/6.png',
			'/projects/IslamicComplexCourts/7.png',
		],
	},
	HajarSportsStadium: {
		id: 'HajarSportsStadium',
		title: 'Hajar Sports Stadium',
		contractor: 'Saudi Building Technic Co',
		client: 'Youth Care',
		clientImage: '/clients/client4.png',
		duration: '',
		description: 'Central air conditioning, fire works, and electrical works',
		furtherDescription:
			'Civil and architectural finishing works and epoxy coating. General site works and asphalt.',
		tags: ['Air Conditioning', 'Fire', 'Electrical'],
		gallery: [
			'/projects/HajarSportsStadium/1.png',
			'/projects/HajarSportsStadium/2.png',
			'/projects/HajarSportsStadium/3.png',
			'/projects/HajarSportsStadium/4.png',
			'/projects/HajarSportsStadium/5.png',
			'/projects/HajarSportsStadium/6.png',
		],
	},
	DiplomaticQuarterGardens: {
		id: 'DiplomaticQuarterGardens',
		title: 'Diplomatic Quarter Gardens',
		contractor: 'ENJAZ for Operation & Maintenance',
		client: 'Royal Commission for Riyadh City.',
		clientImage: '/clients/client29.png',
		duration: '',
		description: 'The rehabilitation of the diplomatic quarter gardens',
		furtherDescription:
			'Replacement of MEP Networks & Architecture Works Stainless Steel Seats, Trees Protection Covers, Pavements, Interlock,..etc.',
		tags: ['Gardens'],
		gallery: [
			'/projects/DiplomaticQuarterGardens/1.png',
			'/projects/DiplomaticQuarterGardens/2.png',
			'/projects/DiplomaticQuarterGardens/3.png',
		],
	},
	KidsGardensPlaysets: {
		id: 'KidsGardensPlaysets',
		title: 'Kids Gardens Playsets',
		contractor: 'ROS for Trading & Contracting',
		client: 'Riyadh Region Municipality',
		clientImage: '/clients/client30.png',
		duration: '',
		description: 'Replacement of Kids Gardens Playsets in Riyadh City.',
		furtherDescription:
			'Replacement of kids garden playsets, fitness games & rubber playgrounds including all related civil works',
		tags: ['Kids', 'Playsets'],
		gallery: [
			'/projects/KidsGardensPlaysets/1.png',
			'/projects/KidsGardensPlaysets/2.png',
			'/projects/KidsGardensPlaysets/3.png',
			'/projects/KidsGardensPlaysets/4.png',
			'/projects/KidsGardensPlaysets/5.png',
			'/projects/KidsGardensPlaysets/6.png',
		],
	},
};

export const otherProjects = [
	{
		id: 'DevelopmentofthebirdmarketinAzizia',
		title: 'Development of the bird market in Azizia',
		contractor: 'Sawaed for Contracting',
		client: 'AgriServ',
		clientImage: '',
		description:
			'Implementation of constructional, architectural and electromechanical works',
		location: 'Riyadh',
	},
	{
		id: 'QiddiyaWaterThemePark',
		title: 'Qiddiya Water Theme Park',
		contractor: 'Voltas / Sawaed for Contracting',
		client: 'Qiddya',
		clientImage: '',
		description:
			'Infrastructure work - supply & installation of storm water system / sewer Network / water supply system / fire fighting system / Chilled water Network / Electrical Installations including switch gear, transformers, Rmu. LV, WLV Ducting & Cable installation',
		location: 'Riyadh',
	},
	{
		id: 'NEOMProject',
		title: 'NEOM Project',
		contractor: 'Shaporji/ Sawaed for Contracting',
		client: 'NEOM ',
		clientImage: '',
		description:
			'Installation, testing, and commissioning infrastructure works (plumbing, fire fighting, medium voltage, low voltage, switch gears, and transformers.',
	},
	{
		id: 'NationalGuardMentalHealthHospital',
		title: 'National Guard Mental Health Hospital',
		contractor: 'Saudi Binladin Group  /  Sawaed for Contracting',
		client: 'National Guard ',
		clientImage: '',
		description:
			'Infrastructure works of supply and installation of rain water system / sewerage system / water supply system / fire fighting system / medium voltage / low voltage / switch gear transformer, etc.',
	},
	{
		id: 'DammamReformatory',
		title: 'Dammam Reformatory',
		contractor: 'Saudi Binladin Group / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description: 'Supply and installation of mechanical and electrical works',
		location: 'Dammam',
	},
	{
		id: 'KAFDProject',
		title: 'KAFD Project',
		contractor: 'Shapoorji Pallonji Mideast / Sawaed for Contracting',
		client: 'KAFD',
		clientImage: '',
		description:
			'Supply & installation of mechanical, electrical, central air conditioning and fire works in towers 4.12 - 4.09',
	},
	{
		id: 'ForensicEvidenceBuilding',
		title: (
			<span>
				Developing the security headquarters of the Ministry of Interior
				<b> Forensic Evidence building</b>
			</span>
		),
		contractor: 'Abdulaziz AlHajaj Co / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description:
			'Civil works, Electromechanical works, Finishing works & General site works',
		location: 'Al Madinah',
	},
	{
		id: 'developingthesecurityheadquartersoftheMinistryofInteriorTrafficBuilding',
		title: (
			<span>
				Developing the security headquarters of the Ministry of Interior{' '}
				<b>Traffic Building</b>
			</span>
		),
		contractor: 'Abdulaziz AlHajaj Co / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description:
			'Civil works, Electromechanical works, Finishing works & General site works',
		location: 'Al Khubair',
	},
	{
		id: 'CivilDefenseBuildingAlHanakiya',
		title: (
			<span>
				Developing the security headquarters of the Ministry of Interior{' '}
				<b>Civil Defense Building</b>
			</span>
		),
		contractor: 'Abdulaziz AlHajaj Co / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description:
			'Civil works, Electromechanical works, Finishing works & General site works',
		location: 'Al Hanakiya',
	},
	{
		title: (
			<span>
				Development of <b>Security headquarters</b>
			</span>
		),
		contractor: 'Abdulaziz AlHajaj Co / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description: 'Implementation of electromechanical works',
		location: 'Badr City area',
	},
	{
		title: (
			<span>
				Development of <b>Security headquarters</b>
			</span>
		),
		contractor: 'Abdulaziz AlHajaj Co / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description:
			'Implementation of construction, architectural and electromechanical works',
		location: 'Khaiber City area',
	},
	{
		title: <span>Forensic Evidence Building</span>,
		contractor: 'Saudi Technical Limited / Sawaed for Contracting',
		client: 'Ministry of Interior',
		clientImage: '',
		description: 'Central air conditioning and fire works and electrical works',
		location: 'Hail city',
	},
	{
		id: 'WaterTanksConveyorLinesMajmaahGovernorate',
		title: 'Implement water tanks and conveyor lines',
		contractor: 'Wajna Co / Sawaed for Contracting',
		client: 'Ministry of Water',
		clientImage: '',
		description:
			'Civil and architectural finishing works and epoxy coating, External works and road works',
		location: 'Al Majmaah Governorate',
	},
	{
		id: 'WaterTanksConveyorLinesMajmaahGovernorate',
		title: 'Implement water tanks and conveyor lines',
		contractor: 'Wajna Co / Sawaed for Contracting',
		client: 'Ministry of Water',
		clientImage: '',
		description:
			'Civil and architectural finishing works and epoxy coating, External works and road works',
		location: 'Dawadmi Governorate',
	},
	{
		id: 'Implementing water tanks for conveyor lines',
		title: 'Implementing water tanks for conveyor lines',
		contractor: 'GETCO / Sawaed for Contracting',
		client: 'Ministry of Water',
		clientImage: '',
		description:
			'Civil and architectural finishing works and epoxy coating. General site works and asphalt',
		location: 'Al-Aflaj Governorate',
	},
	{
		title: 'Implement water tanks and conveyor lines',
		contractor: 'Dngo Contracting Co / Sawaed for Contracting',
		client: 'Ministry of Water',
		clientImage: '',
		description: 'Civil and architectural finishing works and epoxy coating.',
		location: 'Al Buwaib',
	},
	{
		id: 'DrinkingWaterStationsHailAlAhsaRiffaDerma',
		title: 'Design, construction, and operation of drinking water stations',
		contractor: 'Nesma Water Co / Sawaed for Contracting',
		client: 'Ministry of Water',
		clientImage: '',
		description:
			'Installation of HDPE lines for evaporation lakes and conducting the necessary welding operations',
		location: 'Hail and Al-Ahsa, Riffa, and Derma',
	},
	{
		title: 'Lining of evaporation plants',
		contractor: 'GETCO / Sawaed for Contracting',
		client: 'Ministry of Water',
		clientImage: '',
		description: 'Lining the evaporation lakes of the water treatment plant',
		location: 'Delam',
	},
	{
		title: 'Tahlia housing',
		contractor: 'Al-Soumly Company / Sawaed for Contracting',
		client: 'The General Authority for Water Desalination',
		clientImage: '',
		description:
			'Supply and installation of fire works in the general site, buildings and project buildings with a fire pump station.',
		location: 'Al Madinah',
	},
	{
		title: 'Residential complexes - General Authority for Water Transfer',
		contractor: 'Al-Soumly Company / Sawaed for Contracting',
		client: 'Water Desalination Authority',
		clientImage: '',
		description:
			'Central air conditioning and fire works, and electrical works.',
		location: 'Al Madinah',
	},
	{
		title: 'Sewage plant project',
		contractor: 'AlArrab Contracting/ Sawaed for Contracting',
		client: 'Cities Authority',
		clientImage: '',
		description: 'Finishing works, waterproofing and epoxy coating for tanks.',
		location: 'Al Madinah',
	},
	{
		title: 'Water Station',
		contractor: 'AlBayan Group / Sawaed for Contracting',
		client: 'Cities Authority',
		clientImage: '',
		description:
			'Central air conditioning and fire works and electrical works.',
		location: 'Sedair Industrial Zone',
	},
	{
		title: 'Water Station Construction',
		contractor: 'Abdullah Al Saed group / Sawaed for Contracting',
		client: 'Cities Authority',
		clientImage: '',
		description:
			'Lining the evaporation lakes of the water treatment plant in Rafha area',
		location: 'Rafha area',
	},
	{
		title: 'Water Station',
		contractor: 'AlBayan Group / Sawaed for Contracting',
		client: 'Cities Authority',
		clientImage: '',
		description:
			'Central air conditioning and fire works and electrical works.',
		location: 'Abqaiq Industrial Area',
	},
	{
		title: 'Contract for the implementation of water drainage lines',
		contractor: 'SAMA Construction for Contracting  /  Sawaed for Contracting',
		client: 'Municipality of Medina',
		clientImage: '',
		description:
			'Infrastructure works including excavation, installation, connection and testing of pipes, manholes and rain catchers.',
		location: 'Al Madinah',
	},
	{
		title: 'Sports City',
		contractor: 'Upmoc  / Sawaed for Contracting',
		client: 'Ministry of Sports',
		clientImage: '',
		description:
			'Supply and installation of electromechanical structural and architectural works.',
		location: 'Al Kharj',
	},
	{
		title: 'Hajar Sports Stadium',
		contractor: 'Fan Al Me’maar   / Sawaed for contracting',
		client: 'Ministry of Sports',
		clientImage: '',
		description:
			'Civil and architectural finishing works and epoxy coating. General site works and asphalt Central air conditioning works in the stadium building, fire pump room works, as well as complete fire network works.',
	},
	{
		title: 'Villas in the eastern region of Jubail',
		contractor: 'Abdullah AlMishal Co / Sawaed for Contracting',
		client: 'Saudi Aramco',
		clientImage: '',
		description:
			'Central air conditioning system and fire works - firefighting network and electrical works.',
		location: 'Jubail',
	},
	{
		title: 'Waste landfill project',
		contractor: 'Paving & Construction Co/ Sawaed for Contracting',
		client: 'Ministry of Environment',
		clientImage: '',
		description:
			'Lining and isolating lakes with backfill. Tank construction works. Mechanical extension works',
		location: 'Riyadh',
	},
	{
		title: 'Al Miqat Hospital',
		contractor: 'Saudi Taab Co. / Sawaed for Contracting',
		client: 'Ministry of Health',
		clientImage: '',
		description:
			'Central air conditioning installation work in the hospital, including (water cooler station with pumps - air handling units - fan units - ventilation fans). Installing and connecting electrical panels and operating switches',
		location: 'Al Madinah',
	},
	{
		title: 'King Khaled Hospital Project',
		contractor: 'Bytur construction co / Star Taiba / Sawaed for Contracting',
		client: 'Ministry of Higher Education',
		clientImage: '',
		description: 'Central air conditioning and fire works and electrical works',
		location: 'Abha',
	},
	{
		title: 'Medical warehouse constructions',
		contractor: 'Fan Al Me’maar / Sawaed for contracting',
		client: 'Ministry of Higher Education',
		clientImage: '',
		description:
			'Complete central air conditioning works in warehouses, including (combined and separate air conditioning units - all air duct work - ventilation fans) Complete fire network works Sanitary works',
		location: 'King Saud University, Riyadh',
	},
	{
		title: 'Faculty housing',
		contractor: 'Al ARRAB ContractingCO/ Sawaed for Contracting',
		client: 'Ministry of Higher Education',
		clientImage: '',
		description:
			'Central air conditioning system. Fire works Electrical Works. Civil and architectural finishing works on site',
		location: 'King Abdelaziz University in Jeddah',
	},
	{
		title: 'Improvements of Salbookh Airbas',
		contractor: 'BSS Group/ Sawaed for Contracting',
		client: 'Ministry of Defense',
		clientImage: '',
		description:
			'Infrastructure, mechanical, electrical, portable water and Seward system',
		location: '',
	},
	{
		title: 'Construction of King Soliman Air Base in Dirab',
		contractor: 'Al-Bawani Co/ Sawaed for Contracting',
		client: 'Ministry of Defense',
		clientImage: '',
		description:
			'Pavement works, paints, tiles and epoxy paint as an integrated package For King Salman Air Base',
		location: 'Dirab city',
	},
	{
		title: 'Medina Courts Complex',
		contractor: 'Al-saghyir Company/ Sawaed for Contracting',
		client: 'Ministry of Justice',
		clientImage: '',
		description:
			'Design, supply, installation and maintenance of fire network and central air conditioning.',
		location: 'Al Madinah',
	},
	{
		title: 'Residential complexes project - Royal Guard',
		contractor: 'Abdulaziz AlHajaj Co  / Sawaed for Contracting',
		client: 'The Royal Guard',
		clientImage: '',
		description:
			'Civil works for buildings. Mechanical and electrical extension works',
		location: 'Jeddah',
	},
	{
		title: 'Housing Construction project for the Authority’s workers Property',
		contractor: 'Abdullah Al-Mishal Contracting / Sawaed for Contracting',
		client: 'Royal Commission in Jubail',
		clientImage: '',
		description:
			'Central air conditioning system and fire works -  firefighting network. Electrical Works',
		location: 'Jubail',
	},
	{
		title: 'Facilities building project',
		contractor: 'Abdullah Al-Mishal Contracting / Sawaed for Contracting',
		client: 'Royal Commission in Jubail',
		clientImage: '',
		description:
			'Central air conditioning system. Fire works - firefighting network. Electrical Works',
		location: 'Jubail',
	},
	{
		title: 'Rehabilitation of Diplomatic Quarter Gardens',
		contractor: 'ENJAZ for Operation & Maintenance / Sawaed for Contracting',
		client: 'Royal Commission',
		clientImage: '',
		description:
			'Replacement of MEP Networks &Arch. Works Stainless Steel Seats, Trees Protection Covers, Pavements, Interlock,..etc.',
		location: 'Riyadh',
	},

	{
		title: 'Via Riyadh project in the Diplomatic Quarter',
		contractor: 'Architecture civil Co / Sawaed for Contracting',
		client: 'Royal Commission',
		clientImage: '',
		description: 'Supply and implementation of electrical works',
		location: 'Riyadh',
	},
	{
		title: 'Exit-10 Development',
		contractor: 'Shapoorji Pallonji Mideast/Sawaed for Contracting',
		client: 'Saudi Entertainment Authority',
		clientImage: '',
		description:
			'Supply & installation of mechanical and electrical works, including grounding, boards and foundation works, with central air conditioning system and firefighting.',
		location: '',
	},
	{
		title: 'Infrastructure works and rain drainage extensions',
		contractor: 'Abdulaziz Alhajaj Contracting CO / Sawaed for Contracting',
		client: 'Saudi Royal Guard Regiment',
		clientImage: '',
		description: 'Mechanical installation works.',
		location: '',
	},
	{
		title: 'Replacement of Kids Gardens Playsets',
		contractor: 'ENJAZ for Operation & Maintenance / Sawaed for Contracting',
		client: 'Riyadh Region Municipality',
		clientImage: '',
		description:
			'Replacement of Kids Garden Playsets, Fitness Games & Rubber Playgrounds Including All Related Civil Works',
		location: 'Riyadh',
	},
	{
		title: 'Project to establish grain silos',
		contractor: 'Nahda El Eamar / Sawaed for Contracting',
		client: 'The General Authority of Grain Silos',
		clientImage: '',
		description:
			'Finishing works for the administrative building Sales building and cafeteria. Central air conditioning and fire works. Electrical Works',
		location: 'Al-Ahsa',
	},
	{
		title: 'Al-Owaidah palace',
		contractor: 'York Company / Sawaed for Contracting',
		client: 'Al-Owaidah palace',
		clientImage: '',
		description:
			'Supply, installation, replacement and renewal of the central air conditioning system.',
		location: '',
	},
	{
		title: 'Tower 16A in Qatar',
		contractor: 'Sawaed for Contracting',
		client: 'AL-Mutahedah Company',
		clientImage: '',
		description: 'Central air conditioning and fire works Electrical Works',
		location: 'Qatar',
	},
	{
		title: 'Tower 8A in Qatar',
		contractor: 'Sawaed for Contracting',
		client: 'AL-Mutahedah Company',
		clientImage: '',
		description: 'Central air conditioning and fire works Electrical Works',
		location: 'Qatar',
	},
	{
		title: 'Communications and Information Technology Complex',
		contractor: 'Sawaed for Contracting',
		client: 'Al Ra’edah Company',
		clientImage: '',
		description: 'Central air conditioning and fire works Electrical Works',
		location: 'Riyadh ITCC',
	},
	{
		title: 'Medical warehouses of Gulf Pharmaceuticals Company',
		contractor: 'Adwaa Alkhalil CO/ Sawaed for Contracting',
		client: 'Gulf Pharma Company',
		clientImage: '',
		description:
			'Firefighting network & Electrical Works, Design and installation of central air conditioning in warehouses.',
		location: '',
	},
	{
		title: 'Abha Private Hospital',
		contractor: 'Eras  Technology Co/ Sawaed for Contracting',
		client: 'Al-Sheikh Abdulaah Al Thamiri',
		clientImage: '',
		description: 'Central air conditioning and fire works and electrical works',
		location: 'Abha',
	},
	{
		title: 'Al-Basateen Villas',
		contractor: 'Upmoc Co  / Sawaed for Contracting',
		client: 'Al Shakri Group Villas Saudi Investment',
		clientImage: '',
		description:
			'Supply & installation of mechanical, electrical and air conditioning works (VRF), including the establishment and finishing of all internal and external works (100 villas).',
		location: '',
	},
	{
		title: 'Panda Project',
		contractor: 'York Co/ Sawaed for Contracting',
		client: 'Panda Company',
		clientImage: '',
		description:
			'Design, supply and installation of fire network and fire alarm.',
		location: '',
	},
	{
		title: 'Zamil Carton Factory',
		contractor: 'Zamil Group Holding /Sawaed for Contracting',
		client: 'UCIC',
		clientImage: '',
		description:
			'Complete central AC works including (water coolers station with pumps, air handling units, fan coil units, VAV units, ventilation fans) and complete fire network works in the factory.',
		location: '',
	},
	{
		title: 'Golden Chicken Factory',
		contractor: 'York Co / Sawaed for Contracting',
		client: 'Golden Chicken Factory',
		clientImage: '',
		description:
			'Design & implementation of central AC works in the factory, including installation of units with connections, extensions and operation.',
		location: '',
	},
	{
		title: 'SPPF Factory',
		contractor: 'Elm Company /Sawaed for Contracting',
		client: 'PEG Alaa El Essawy',
		clientImage: '',
		description:
			'Supply, installation and operation of central air conditioning, plumbing and electrical systems.',
		location: '',
	},
	{
		title: 'Alam Factory',
		contractor: 'Pandokash /Sawaed for Contracting',
		client: 'Elm Factory',
		clientImage: '',
		description: 'Asphalt and landscape works in the factory.',
		location: 'Sadier',
	},
	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },

	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },
	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },

	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },
	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },

	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },
	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },
	//////////
	// {
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '',
	// 	description: '',
	// 	location: '',
	// },
];

/*
	KingSalmanAirbase: {
			id: 'KingSalmanAirbase',
			title: '',
			contractor: '',
			client: 'Al Bawani',
			clientImage: '/clients/client.png',
			duration: '2 years',
			description: 'Curbstone, Plaster, Paints, Block, Tiles, and Epoxy Coating works as a package for King Salman Airbase at Dirab City. ',
			furtherDescription: '',
			tags: [],
			gallery: [],
		},

*/
